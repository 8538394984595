import React, { useEffect, useState, useContext } from "react"
import { graphql, Link } from "gatsby"
import { Container } from "react-bootstrap"
// import { DEEPVIEW_BRANCH } from "@constants"
import { isBrowser, replaceLinkPlaceholder } from "@libs"
import { BranchContext } from "@providers"
import { Layout2022Q3 } from "@layout"
import SEO from "@components/seo"
import { MainBlockJoinColony } from "@components/main-blocks"
import "@styles/pages/_content.scss"

// cta_uri aka uri = "app_navigator?nav=rewards&offer_tag_id=601f6b1d-196b-4393-bdba-d96a717dd590&
const createBranchData = (uri) => ({
  data: {
    $deeplink_path: `atmapp://${uri}`,
    // $desktop_deepview: DEEPVIEW_BRANCH.desktop_default,
  },
})

export default function PostTemplate({ data: { markdownRemark } }) {
  const { getBranch } = useContext(BranchContext)
  const { frontmatter, html } = markdownRemark
  const defaultLink = `https://www.atm.com/${frontmatter.cta_uri}`
  const [htmlWithCorrectLink, setHtmlWithCorrectLink] = useState(
    replaceLinkPlaceholder(html, defaultLink, "CTA_LINK_PLACEHOLDER")
  )

  useEffect(() => {
    const fetchBranchLink = async () => {
      if (isBrowser) {
        try {
          const branch = await getBranch()
          branch.link(createBranchData(frontmatter.cta_uri), (err, newLink) => {
            if (err) {
              throw new Error("Branch link could not be created", err)
            } else {
              setHtmlWithCorrectLink(
                replaceLinkPlaceholder(
                  htmlWithCorrectLink,
                  newLink,
                  defaultLink
                )
              )
            }
          })
        } catch (e) {
          console.error("Error creating branch link: ", e)
        }
      }
    }

    fetchBranchLink()
  }, [])

  return (
    <Layout2022Q3>
      <SEO
        title={frontmatter.title_tag || frontmatter.title}
        overrideTitleTemplate="%s"
      />
      <div className={`main-block-content-container`}>
        <Container as="article" fluid="md">
          <header>
            {frontmatter.hide_title ??
              (frontmatter.title && (
                <h1 className="title">{frontmatter.title}</h1>
              ))}
            {frontmatter.hide_subtitle ??
              (frontmatter.subtitle && (
                <h2 className="subtitle">{frontmatter.subtitle}</h2>
              ))}
            <div className="tags">
              {frontmatter.tags &&
                frontmatter.tags.map((tag) => (
                  <span key={tag}>
                    <Link
                      to={`/colony-guides/?tag=${encodeURIComponent(
                        tag
                      )}#filter-posts`}
                    >
                      {tag}
                    </Link>
                  </span>
                ))}
            </div>
            {frontmatter.last_updated_at && (
              <div className="publish-info">
                {frontmatter.hide_date ?? (
                  <span className="date">
                    Last updated {frontmatter.last_updated_at}
                  </span>
                )}
                {!frontmatter.hide_date && !frontmatter.hide_author && (
                  <span className="separator">|</span>
                )}
                {frontmatter.hide_author ?? (
                  <span className="author">
                    By <Link to="/colony-guides/">Penny & Buck</Link>
                  </span>
                )}
              </div>
            )}
            {frontmatter.image && (
              <div className="image">
                <img src={frontmatter.image} alt={frontmatter.title} />
              </div>
            )}
            {frontmatter.hide_disclosure ?? (
              <div className="disclosure">
                <p>
                  ATM.com&reg; may receive affiliate compensation for purchases
                  made from affiliate sellers when you click on their links on
                  our site.{" "}
                  <Link to="/brands/?utm_source=atm&utm_medium=website&utm_campaign=Advertising%20Disclosure%20list%20of%20partners">
                    Here is a list of our partners
                  </Link>
                  .
                </p>
              </div>
            )}
          </header>
          <div
            className="post-body"
            dangerouslySetInnerHTML={{ __html: htmlWithCorrectLink }}
          />
        </Container>
        {frontmatter.hide_join_colony ?? (
          <MainBlockJoinColony
            h1="Join the Ant Colony!"
            h2="Unlock opportunities to earn extra cash, build your savings, and learn investing."
          />
        )}
      </div>
    </Layout2022Q3>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title_tag
        title
        subtitle
        last_updated_at(formatString: "MMMM D, YYYY")
        image
        cta_uri
        tags
        hide_title
        hide_subtitle
        hide_date
        hide_author
        hide_disclosure
        hide_join_colony
      }
    }
  }
`
